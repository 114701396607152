<template>
  <div id="check-in" class="bounce-in-right">
    <div>
      <img id='accessDenied' :src="require('../assets/img/negado.png')" />
    </div>
    <div>
      <img id='accessOk' :src="require('../assets/img/liberado.png')" />
    </div>
    <div>
      <img id="text" src="../assets/img/clique_nos_pratos.png" />
    </div>
    <div id="flag-area" class="container">
      <div v-for="(coluna, k) in mtzNames" :key="k">
        <div v-for="(cidade, j) in coluna" :key="j">
          <div class="icon-area">
          <img class='icon' :id="cidade" v-on:click="clicked(cidade)" :src="require('../assets/img/flags_icons/' + cidade + '.png')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#check-in {
  display:flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  align-items: center;
}


#accessDenied, #accessOk {
  width: 80%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  z-index: 1;
}

#text {
  width: 75%;
  max-height: 65px;
}

.icon {
  width: auto;
  height: auto;
  margin: 8px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 500px;
  opacity:0.7;
}

</style>

<script>
export default {
  name: "CheckIn",
  components: {},
  data() {
    return {
      windowWidth:0,
      rows: 6,
      columns: 3,
      listNames:[
        'MILANO', 'NAPOLI', 'TORINO', 'AOSTA', 'CAMPOBASSO', 'VENEZIA',
        'TRENTO', 'CATANZARO', 'GENOVA', 'BOLOGNA', 'TRIESTE', 'ROMA',
        'BARI', 'CAGLIARI', 'PALERMO', 'PERUGIA', 'FIRENZE', 'POTENZA'],
      mtzNames: [],
      password: [
        'BARI', 'FIRENZE', 'GENOVA', 'MILANO'
      ],
      pressed_flags:[],
      access: 'negado',
    };
  },
  created(){
    this.listNames.sort();
    this.organizeColums();
  },
  mounted(){
    this.$nextTick(function () {
      setTimeout(() => this.updateHeightContent(), 100);
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    })
  },

  methods: {
    // Inicializa o mtzNames de acordo com a quantidade de linha, coluna e a lista de nomes
    organizeColums(){
      var idx = 0;
      for(var col = 0; col < this.columns; col++){
        this.mtzNames.push([]);
        idx = col;
        for(var row = 0; row < this.rows; row++){
          this.mtzNames[col].push(this.listNames[idx])
          idx = idx + this.columns;
        }
      }
    },
    // Identifica a largura da tela
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      setTimeout(() => this.updateHeightContent(), 100);
      for (var i= 0; i < this.rows + 1 ; i++) { 
        setTimeout(() => this.updateHeightFlags(), 100);
      }
    },
    // altera altura da area de botões (flag-area) automaticamente
    updateHeightContent: function(){
      var heightLogoHnk = document.getElementById('div-logo-hnk').offsetHeight;
      var heightText = document.getElementById('text').offsetHeight;
      var heightHeader = heightLogoHnk + heightText;
      let el = document.getElementById('flag-area');
      el.style.cssText = 
        'height:  calc( 100vh - '+ heightHeader +'px );'
    },
    
    // atualiza tamanho das bandeiras de acordo com a altura da tela
    updateHeightFlags: function() {
      var altura = document.getElementById('flag-area').clientHeight;
      let max_height_flag = Math.round(altura/this.rows) - (16);
      var largura = document.getElementById('flag-area').clientWidth;
      let max_width_flag = Math.round(largura/this.columns) - (30);

      var myElements = document.querySelectorAll(".icon");
      for (var i= 0; i < myElements.length ; i++) { 
        myElements[i].style.maxHeight = (max_height_flag +"px")
        myElements[i].style.maxWidth = (max_width_flag +"px")
      }
    },
    
    // se alguma bandeira foi clicada
    clicked: function(cidade) {
      var myElement = document.getElementById(cidade);
      // se não está pressionado
      if(!this.isPressed(cidade)){
        myElement.style.opacity= "1";
        myElement.style.boxShadow = "0px 0px 10px 1px #f2e22d";
        this.pressed_flags.push(cidade);
      }else{ // se já está pressionado
        myElement.style.opacity= "0.7";
        myElement.style.boxShadow = "0px 0px 0px 0px #f2e22d";
        this.pressed_flags = this.arrayRemove(this.pressed_flags, cidade);
      }
      setTimeout(() => this.verifyPassword(), 100);
    },

    // verifica a senha
    verifyPassword: function(){
      // verifica se apertou a quantidade certa de bandeiras
      if (this.password.length !== this.pressed_flags.length) {
        return false;
      }
      this.password.sort();
      this.pressed_flags.sort();
      // se apertou qtd certa verifica se a senha é a correta
      for (var i = 0; i < this.password.length ; i++) {
		    if (this.password[i] !== this.pressed_flags[i]) {
          this.reset();
          this.showMsgaccess('negado');
          return false;
        }
	    }

      // senha correta então
      this.reset();
      this.access = "liberado";
      this.showMsgaccess('liberado');
      this.openDoor();
      return true;
    },
    
    // Mostra widget de acesso liberado ou negado
    showMsgaccess: function(access) {
      var myElement;
      var isCorrect = false;
      if('negado' == access){
        myElement = document.getElementById('accessDenied');
      }else if('liberado' == access){
        isCorrect = true;
        myElement = document.getElementById('accessOk');
      }
      myElement.style.visibility = "visible";
      setTimeout(() => {
        myElement.style.visibility = "hidden";
        this.access = "negado";
        if(isCorrect){
          this.$router.back();
        }
      }, 2000);
    },
    
    // abrir porta
    openDoor: function(){
      setTimeout(() => {
        fetch("https://hnk00.cyma.digital/scan-porta/10").then(function(res) { 
          this.dialog = true;
        })
        //console.log('porta liberada')
      },200)
      
    },

    // reseta todos os botões da tela
    reset: function(){
      var lenArray = this.pressed_flags.length;
      for (var i = 0; i < lenArray  ; i++) {
        let myElement = document.getElementById(this.pressed_flags[i]);
        myElement.style.opacity= "0.7";
        myElement.style.boxShadow = "0px 0px 0px 0px #f2e22d";
	    }
      this.pressed_flags = [];
    },

    // verifica se o botão está pressionado
    isPressed: function(cidade){
      var isPressed = false;
      for (var i=0; i < this.pressed_flags.length; i++){
        if(this.pressed_flags[i] == cidade){
          isPressed = true;
          break;
        }
      }
      return isPressed;
    },
    
    // remove elemento do array por valor
    arrayRemove: function(arr, value) {
      return arr.filter(function(geeks){
          return geeks != value;
      });
    }
  },
};
</script>
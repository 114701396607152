<template>
  <div id="app">
    <div id="div-logo-hnk">
      <img id="logo-heineken" src="./assets/img/logo_heineken.png"/>
    </div>
    <Transition name="fade" mode="out-in">
      <router-view />
    </Transition>
  </div>
</template>

<style>
#app {
  box-sizing: border-box;
  font-family: "Montserrat-Medium", sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #0f0f0f;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

#logo-heineken {
  margin-top: 5px;
  max-width: 75%;
  max-height: 100px;
  margin-bottom: 20px;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-100vw);
    transform: translateX(-100vw);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(200vw);
    transform: translateX(200vw);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

</style>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
    };
  },
  methods: {
    
  },
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Checkin from '../views/CheckIn.vue'

Vue.use(VueRouter)

const routes = [
{
  path: '/',
  name: 'Home',
  component: Home
},
{
  path: '/checkin',
  name: 'checkin',
  component: Checkin
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
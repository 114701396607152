<template>
<router-link to="/checkin" style="text-decoration: none; color: inherit;">
  <div id="home" class="bounce-in-right">
    <div class="container">
      <img class="theS" src="../assets/img/theS.png" >
    </div>
    <div class="container">
      <img class="button_codigo" src="../assets/img/button_cod_secreto.png" >
    </div>
  </div>
</router-link>
</template>

<style>
#home {
  display:flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100vw;
  align-items: center;
}

.theS {
  width: 55%;
  margin-top: 20px;
}

.button_codigo {
  width:75%;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-name: cliqueloop;
}

</style>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
    };
  },
  mounted(){
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
      setTimeout(() => this.updateHeightContent(), 100);
    })
  },
  methods: {
    getWindowWidth() {
      this.updateHeightContent();
    },
  
    updateHeightContent: function(){
      var altura = document.getElementById('div-logo-hnk').offsetHeight;
      this.heightLogo = altura;
      let el = document.getElementById('home');
      el.style.cssText = 
        'height:  calc( 100vh - '+ altura +'px );'
    }
  },
};
</script>